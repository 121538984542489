<template>
  <b-row>
    <b-col cols="3">
      <TreeView :setDong="setDong" :selected="dongSearch == '' ? '전체' : dongSearch"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">방문차량 신청 목록 (총 신청 건수 10건)</h5>
        </b-card-body>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :current-page="currentPage"
          :per-page="perPage"
          :fields="fields"
        >

          <template #cell(place)="data">
            <a style='font-weight: 700; cursor: pointer;' @click="openStatusChange">
            {{data.item.place}}
            </a>
          </template>

          <template #cell(status)="data">
            <b-button variant="outline-success" v-if="(data.item.status == 1)">확인</b-button>

            <b-button variant="outline-danger" @click="deny(data.item.idx)" v-if="(data.item.status == 0)">거절</b-button>&nbsp;
            <b-button variant="outline-primary" v-if="(data.item.status == 0)">승인</b-button>
          </template>

          <template #cell(now_status)="data">
            <b-badge variant="success" v-if="(data.item.now_status == 0)">요청</b-badge>

            <b-badge variant="primary" v-if="(data.item.now_status == 1)">승인</b-badge>
            <b-badge variant="danger" v-if="(data.item.now_status == 2)">거절</b-badge>
          </template>

        </b-table>

        <div class="row p-3 m-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0"
          ></b-pagination>
        </div>
      </b-card>
    </b-col>

  </b-row>
  
</template>

<style>
.b-table {
  border: 1px solid #eee
}
</style>

<script>
import TreeView from '../components/apps/TreeView';
export default {
  name: "CarVisit",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "target",
        label: "신청 세대",
      },
      {
        key: "number",
        label: "차량 번호",
      },
      {
        key: "date",
        label: "신청일",
      },
      {
        key: "date_req",
        label: "차량 방문일",
      },
      {
        key: "now_status",
        label: "상태",
      },
      {
        key: "status",
        label: "사용 승인",
      }
    ],
    items: [
      {idx: "1", target: "502동 1003호", number: "11가 1234", date: "22.08.16", date_req: "22.08.16 09:00 ~ 22.08.16 10:00", now_status : 0, status: 0},
        {idx: "2", target: "301동 602호", number: "245우 9315", date: "22.07.05", date_req: "22.07.19 13:50 ~ 22.07.19 18:00", now_status : 0,  status: 0},
        {idx: "3", target: "104동 1601호", number: "65노 0887", date: "22.07.17", date_req: "22.07.17 10:00 ~ 22.07.18 09:00", now_status : 1,  status: 1},
        {idx: "4", target: "507동 1203호", number: "60라 7213", date: "22.07.13", date_req: "22.07.15 18:40 ~ 22.07.15 20:00", now_status : 1,  status: 1},
        {idx: "5", target: "304동 302호", number: "21우 8176", date: "22.02.01", date_req: "22.02.06 04:00 ~ 22.02.06 06:00", now_status : 1,  status: 1},
        {idx: "6", target: "205동 801호", number: "56누 8716", date: "22.01.14", date_req: "22.01.14 11:30 ~ 22.01.14 15:40", now_status : 1,  status: 1},
        {idx: "7", target: "408동 504호", number: "403주 2975", date: "21.12.07", date_req: "21.12.07 10:30 ~ 22.12.07 10:40", now_status : 2,  status: 1},
        {idx: "8", target: "106동 201호", number: "31바 7174", date: "21.10.24", date_req: "21.10.24 14:20 ~ 21.10.25 01:00", now_status : 1,  status: 1},
        {idx: "9", target: "203동 902호", number: "67나 8016", date: "21.09.11", date_req: "21.09.11 19:10 ~ 21.09.11 20:00", now_status : 2,  status: 1},
        {idx: "10", target: "403동 1003호", number: "13거 4291", date: "21.05.08", date_req: "21.05.09 01:30 ~ 21.05.09 01:40", now_status : 1,  status: 1},
        {idx: "11", target: "403동 1003호", number: "13거 4291", date: "21.05.08", date_req: "21.05.09 01:30 ~ 21.05.09 01:40", now_status : 1,  status: 1}
    ],
    totalRows: 0,
    currentPage: 1,
    perPage: 10,
    dongSearch: "",

  }),
  components: {TreeView},
  methods: {
    deny(){
      this.$swal.fire({
        title: '거절 사유 입력',
        html: "<input type='text' class='form-control'></input>",
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',

    
      })
    },
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
      }
      else{
        this.dongSearch = "";
      }
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  },
};
</script>
